<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="m-0 auth-inner">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="ml-1 brand-text text-primary">Studenia</h2>
      </b-link>-->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="7" class="p-5 d-none d-lg-flex align-items-center">
        <div
          class="px-5 w-100 d-lg-flex align-items-center justify-content-center"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="5" class="p-5 px-2 auth-bg">
        <div class="my-2 text-center">
          <vuexy-logo width="140" />
        </div>
        <b-col sm="8" md="6" lg="12" class="pt-1 mx-auto px-xl-2 pt-lg-2">
          <b-card-title title-tag="h2" class="mb-1 font-weight-bold"
            >Admin Panel Studenia</b-card-title
          >
          <b-card-text class="mb-2"
            >Silahkan Login untuk masuk ke dalam sistem</b-card-text
          >

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="mt-2 auth-login-form" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                  >Remember Me</b-form-checkbox
                >
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
                :disabled="isSubmiting"
              >
                <div v-if="isSubmiting">
                  <b-spinner small />
                  <span class="sr-only">Loading...</span>
                </div>

                <div v-else>
                  <span>Sign in</span>
                </div>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import repository from "@repofactory";
import router from "@/router";

const authRepo = repository.get("auth");
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isSubmiting: false,
      status: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    showToast(title, text, variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          variant,
          icon,
        },
      });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.isSubmiting = true;
          authRepo
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              store.state.auth.isLogin = true;
              store.state.auth.user = response.data.user;
              store.state.auth.permissions = response.data.user.permissions;
              localStorage.setItem("token", response.data.access_token);
              router.push("/");
              this.isSubmiting = false;
            })
            .catch((error) => {
              if (error.response) {
                this.showToast(
                  "Error",
                  error.response.data.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              } else if (error.request) {
                this.showToast(
                  "Error",
                  "Tidak bisa request data ke server",
                  "danger",
                  "AlertTriangleIcon"
                );
              } else {
                this.showToast(
                  "Error",
                  error.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              }
              this.isSubmiting = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
